import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import { change, clearSubmitErrors, formValueSelector, initialize, reset } from 'redux-form';

import { addAlert } from 'components/Alerts/actions';
import { hideBusy, showBusy } from 'components/Busy/actions';
import { showDialog as showFPPModal } from 'components/FPModal/actions';
import Loading from 'components/Loading';
import { go as navigate } from 'components/Navigate/actions';
import BasePage from 'components/Page';
import Retry from 'components/Retry';
import { fetch as fetchCustomer } from 'entities/Customer/actions';
import { fetchQuotes, fetchServices } from 'entities/Facility/actions';
import { getIdByFacilityCode, getIdByNetParkCode } from 'entities/Facility/util';
import {
  add as addReservation,
  fetch as fetchReservation,
  update as updateReservation,
} from 'entities/Reservation/actions';
import { storeOnContext } from 'util/Context/actions';
import { send as sendSOS } from 'util/SOS/actions';
import ConfirmForm from './components/ConfirmForm';
import CustomerForm from './components/CustomerForm';
import DatesForm from './components/DatesForm';
import LocationForm from './components/LocationForm';
import PackagesForm from './components/PackagesForm';
import PayForm from './components/PayForm';
import ServicesForm from './components/ServicesForm';
import TimesForm from './components/TimesForm';
import VehicleForm from './components/VehicleForm';
import * as c from './constants';
import './styles.scss';

/* Book reservation. */
class BookReservation extends BasePage {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for reCAPTCHA
    this.recaptcha = null;

    // for step management
    this.state = {
      ...this.state,
      locationCode: null,
      services: [],
      packages: [],
      prepay: false,
      step: 1,
      jumpIn: false,
      jumping: false,
    };

    // for edit management
    this.state = {
      ...this.state,
      fetchError: false,
      notFound: false,
      loading: false,
      original: null,
    };

    // start with a clean form
    this.props.resetForm();

    // if we can, default the facility
    if (
      props.facilities &&
      props.facilities.find((f) => f.id === Number(localStorage.getItem('selectedFacility')))
    ) {
      this.props.setFacility(localStorage.getItem('selectedFacility'));
    }
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // load reCAPTCHA
    load(process.env.REACT_APP_RECAPTCHA_KEY, {
      explicitRenderParameters: {
        badge: 'bottomleft',
      },
    })
      .then((recaptcha) => {
        // capture the instance
        this.recaptcha = recaptcha;

        // show the badge
        console.debug('Showing reCAPTCHA badge');
        $('.grecaptcha-badge').removeClass('png-hidden');
      })
      .catch((e) => {
        console.error('Failed to load reCAPTCHA', e);
      });

    // if the parking provider is down, we can't be here
    if (!this.props.status?.parking) {
      // go home
      return this.props.parkingProviderDisabled();
    }

    // if we are editing a reservation, load it
    if (!this.state.loading) {
      this.loadReservation(this.props);
    }

    // let's see if we have a specific facility for an addition;
    // if this is an edit, we still need to do this to make sure
    // the facility gets onto the state
    var facilityId;
    if (this.props.match && this.props.match.params && this.props.match.params.facilityId) {
      // find this facility
      if (this.props.facilities) {
        for (var i = 0; i < this.props.facilities.length; i++) {
          if (String(this.props.facilities[i].id) === String(this.props.match.params.facilityId)) {
            // set it on the form
            facilityId = this.props.facilities[i].id;
            this.props.setFacility(facilityId);

            // skip the location choice
            this.setState({
              locationCode: this.props.facilities[i].netParkCode,
              step: c.STEPS.LOCATION + 1,
            });
            break;
          }
        }
      }

      // if we found a facility, let's see if we have a specific end time
      if (facilityId) {
        if (this.props.match && this.props.match.params && this.props.match.params.end) {
          // if we have an end time, we should have a start time, but
          // let's be sure
          if (this.props.match && this.props.match.params && this.props.match.params.start) {
            // jump into the flow at the times so the user can confirm
            this.setState({
              step: c.STEPS.DATES,
              jumpIn: true,
            });
            this.props.setDates(
              Number(this.props.match.params.start),
              Number(this.props.match.params.end),
            );
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if we are editing a reservation, load it
    if (!this.state.loading) {
      this.loadReservation(this.props);
    }

    // edge case; if we don't have a location, make sure we're on step 1
    if (!this.props.facilityId) {
      // new object
      this.setState({ step: c.STEPS.LOCATION });
    }

    // if we jumped in, finish the jump...
    if (this.state.jumpIn && !this.state.jumping) {
      this.setState({ jumping: true });
      this.onNext({
        facilityId: this.props.facilityId,
        start: this.props.start,
        end: this.props.end,
      });
    }

    // did a customer just login or logout?
    if (prevProps.customer !== this.props.customer) {
      // if we're at or past the packages step, we need to re-fetch packages
      if (this.state.step >= c.STEPS.PACKAGES) {
        // note the existing packages
        const existingPackages = this.state.packages;

        // get the facility
        const facility = this.props.facilities.find((f) => f.id === Number(this.props.facilityId));

        // re-fetch packages
        this.fetchPackages(facility, this.props.currentValues).then((packages) => {
          // did they change?
          let changed = false;
          if (existingPackages && existingPackages.length === packages.length) {
            for (var i = 0; i < existingPackages.length; i++) {
              const existing = existingPackages[i];
              changed = changed || !packages.find((p) => p.rateName === existing.rateName);
            }
          } else {
            // yes
            changed = true;
          }

          // if they changed, we need to kick the customer back to the packages step
          if (changed) {
            this.setState({ step: c.STEPS.PACKAGES });

            // also, save them on the state for later
            this.setState({ packages: packages });
          }
        });
      }

      // if the customer went away...
      if (!this.props.customer) {
        // ... forget the FP number...
        this.props.setCustomer({ ...this.props.currentValues.customer, alternateId: null });

        // ... and clear points redeemed
        this.props.setFPPRedeemed(0);
      } else {
        // we have a customer now; set it on the form, but don't overwrite
        // anything that's already there other than last name and email
        this.props.setCustomer({
          ...this.props.customer,
          ...this.props.currentValues.customer,
          lastName: this.props.customer.lastName,
          email: this.props.customer.email,
        });
      }

      // always clear payment information since package options may have changed
      this.props.clearValues(['payment']);
    }
  }

  componentWillUnmount() {
    // parent, for lifecycle logging
    super.componentWillUnmount();

    // hide the badge
    console.debug('Hiding reCAPTCHA badge');
    $('.grecaptcha-badge').addClass('png-hidden');
  }

  /**
   * The timestamps we have upon creation were established assuming the browser's time zone, but
   * we need we need them to be in the facility's time zone.
   *
   * When users pick a date and time, they are conceptually picking a date and time in the
   * facility's time zone. For example, if a customer is sitting in Chicago and booking a spot
   * in Charlotte, and they pick 8:00 AM, they are picking 8:00 AM in Charlotte, not 8:00 AM in
   * Chicago. However, the timestamps we have are in Chicago time because they drive off of the
   * browser's time zone. Therefore, we need to convert them to the facility's time zone.
   *
   * Assuming a time of 8:00 AM Central, we need to make it 8:00 AM Eastern. Note that we do NOT
   * take 8:00 AM Central and make it 9:00 AM Eastern, which is what would happen if we just
   * displayed the timestamp in Eastern time.
   */
  browserToFacilityTZ = (facility, { start, end }) => {
    // get the facility's time zone
    const facilityTZ = facility.timeZone;

    /**
     * Changes the time zone of a timestamp without changing the time. For example, if the timestamp
     * is 8:00 AM Central, and we want to convert it to Eastern, we need to make it 8:00 AM Eastern,
     * not 9:00 AM Eastern.
     *
     * The source time zone is assumed to be the browser's time zone.
     */
    const changeTimezone = (timestamp, toTZ) => {
      // format the timestamp; this will use the browser's time zone
      const formatted = moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');

      // now parse it back in the target time zone
      return moment.tz(formatted, 'YYYY-MM-DD HH:mm:ss', toTZ).unix();
    };

    // change the time zone of the start and end times, but do it on a new object
    return {
      start: changeTimezone(start, facilityTZ),
      end: changeTimezone(end, facilityTZ),
    };
  };

  /**
   * The timestamps on the reservation we just loaded were set assuming the facility's time zone,
   * but we need we need them to be in the browser's time zone.
   *
   * When users picked a date and time upon reservation creation, they conceptually picked a date
   * and time in the facility's time zone. For example, if a customer is sitting in Chicago and
   * booked a spot in Charlotte, and they picked 8:00 AM, they meant 8:00 AM in Charlotte, not
   * 8:00 AM in Chicago. Therefore, we converted them to the facility's time zone before saving.
   *
   * We now need to do the inverse; convert them to the browser's time zone. For the example above,
   * if the reservation was made for 8:00 AM Central, and the user is in Eastern time, we need to
   * make it 8:00 AM Eastern, not 7:00 AM Eastern. It will be converted back to Central time when
   * the reservation is saved.
   */
  facilityToBrowserTZ = (facility, { start, end }) => {
    // get the facility's time zone
    const facilityTZ = facility.timeZone;

    /**
     * Changes the time zone of a timestamp without changing the time. For example, if the timestamp
     * is 8:00 AM Central, and we want to convert it to Eastern, we need to make it 8:00 AM Eastern,
     * not 9:00 AM Eastern.
     *
     * The destination time zone is assumed to be the browser's time zone.
     */
    const changeTimezone = (timestamp, fromTZ) => {
      // format the timestamp; this will use the facility's time zone
      const formatted = moment.unix(timestamp).tz(fromTZ).format('YYYY-MM-DD HH:mm:ss');

      // now parse it back in the browser's time zone
      return moment(formatted, 'YYYY-MM-DD HH:mm:ss').unix();
    };

    // change the time zone of the start and end times, but do it on a new object
    return {
      start: changeTimezone(start, facilityTZ),
      end: changeTimezone(end, facilityTZ),
    };
  };

  loadReservation(props) {
    if (
      !this.state.notFound &&
      props.match &&
      props.match.params &&
      props.match.params.reservationId &&
      props.match.params.facilityId &&
      props.match.params.customerId
    ) {
      // if we already have the desired reservation, do nothing
      if (
        this.state.original &&
        String(this.state.original.id) === String(props.match.params.reservationId)
      ) {
        return;
      }

      // loading...
      this.setState({
        fetchError: false,
        notFound: false,
        loading: true,
        original: null,
      });

      // find this reservation
      props
        .fetchReservation(
          props.match.params.facilityId,
          props.match.params.customerId,
          props.match.params.reservationId,
          this.props.customer,
        )
        .then((reservation) => {
          // one quirk; we always get back a location code on the
          // customer, even if he's not a registered customer at the
          // location, and this can cause problems when quoting; if
          // the logged in customer matches what we got back, we
          // leave it alone, otherwise we clear that field
          if (
            !this.props.customer ||
            !this.props.customer.locationCode ||
            this.props.customer.lastName !== reservation.customer.lastName ||
            this.props.customer.email !== reservation.customer.email ||
            this.props.customer.locationCode !== reservation.customer.locationCode
          ) {
            // either there is no logged in customer, the reservation is
            // for someone other than the logged in customer, or the logged
            // in customer's primary location doesn't match the reservation's
            // primary location; this is valid even if the reservation was
            // made by an FPP customer because you have to be logged in to
            // use points
            delete reservation.customer.locationCode;
          }

          // get the reservation's facility
          const facility = this.props.facilities.find((f) => f.code === reservation.facilityCode);

          // we should definitely have one, but let's be safe
          if (facility) {
            // convert the reservation's timestamps to the browser's time zone
            reservation = { ...reservation, ...this.facilityToBrowserTZ(facility, reservation) };

            // update the form
            this.props.setDates(reservation.start, reservation.end);
          }

          // set it on the state
          this.setState({
            fetchError: false,
            notFound: false,
            original: reservation,
          });
        })
        .catch((e) => {
          // log it
          console.error('Error fetching reservation:', e);

          // is this a "reservation not found" error?
          if (e.code && e.code === 6000) {
            // this is a special case
            this.setState({
              notFound: true,
            });
          }

          // flag it so we can show an error
          this.setState({ fetchError: true });
        })
        .finally(() => {
          // no longer loading
          this.setState({
            loading: false,
          });
        });
    } else if (this.state.original) {
      // this happens if the user chooses to create a new reservation
      // from within the edit flow; start everything over
      this.setState({
        fetchError: false,
        notFound: false,
        loading: false,
        original: null,
      });

      props.cancel();
    }
  }

  fetchServices = (facility, { start, end }) => {
    // convert the timestamps to the facility's time zone
    ({ start, end } = this.browserToFacilityTZ(facility, { start, end }));

    // invoke service selection
    return this.props.fetchServices(facility.id, start, end);
  };

  fetchPackages = (facility, { start, end, services, fppRedeemed }) => {
    // convert the timestamps to the facility's time zone
    ({ start, end } = this.browserToFacilityTZ(facility, { start, end }));

    // we should not have a customer on the reservation at this point, but we
    // can if the user got past the customer screen and then backed up; it can
    // cause problems with quoting (if not an FPP customer at this location)
    const useCustomer =
      this.state.locationCode &&
      this.props.customer &&
      this.state.locationCode === this.props.customer.locationCode;

    // invoke package selection
    return this.props
      .fetchPackages(
        facility.id,
        start,
        end,
        useCustomer ? this.props.customer.email : null,
        services,
        fppRedeemed,
      )
      .then((packages) => {
        // does this browser support Apple Pay?
        let applePaySupported = false;
        try {
          applePaySupported = window.ApplePaySession && window.ApplePaySession.canMakePayments();
        } catch {
          // not supported
        }

        // if we have no payment providers available, filter out prepaid packages
        if (
          !this.props.status?.payments?.creditCard &&
          !this.props.status?.payments?.payPal &&
          (!applePaySupported || !this.props.status?.payments?.applePay) &&
          !this.props.status?.payments?.googlePay
        ) {
          packages = packages.filter((p) => !p.prepay);
        }

        return packages;
      });
  };

  onNext = (values) => {
    // get the reservation's facility
    const facility = this.props.facilities.find((f) => f.id === Number(values.facilityId));

    // if necessary, call the correct handler
    switch (this.state.step) {
      case c.STEPS.LOCATION:
        // capture the location's netPark code
        if (values.facilityId && this.props.facilities) {
          for (var i = 0; i < this.props.facilities.length; i++) {
            if (String(this.props.facilities[i].id) === String(values.facilityId)) {
              // put it on the state...
              this.setState({
                locationCode: this.props.facilities[i].netParkCode,
              });
              break;
            }
          }
        }

        // increment the step
        this.setState({ step: c.STEPS.DATES });
        break;
      case c.STEPS.DATES:
        // if we jumped in, we're now done
        if (this.state.jumpIn) {
          this.setState({ jumpIn: false, jumping: false });
        }

        // increment the step
        this.setState({ step: c.STEPS.TIMES });
        break;
      case c.STEPS.TIMES:
        // check for services...
        this.fetchServices(facility, values)
          .then((services) => {
            // save them on the state for later
            this.setState({ services: services });

            // if we didn't get services, we skip that screen
            if (!services || services.length === 0) {
              // move straight to package selection
              this.fetchPackages(facility, values).then((packages) => {
                // make sure we got some
                if (packages && packages.length > 0) {
                  // save them on the state for later
                  this.setState({ packages: packages });

                  // move to package selection
                  this.setState({ step: c.STEPS.PACKAGES });
                } else {
                  // go to date selection
                  this.setState({
                    step: c.STEPS.LOCATION + 1,
                  });
                }

                // if we jumped in, we're now done
                if (this.state.jumpIn) {
                  this.setState({ jumpIn: false, jumping: false });
                }
              });
            } else {
              // move to service selection
              this.setState({
                step: c.STEPS.SERVICES,
              });

              // if we jumped in, we're now done
              if (this.state.jumpIn) {
                this.setState({ jumpIn: false, jumping: false });
              }
            }
          })
          .catch((e) => {
            // skip services
            console.error("Error fetching services; we'll skip that screen", e);
            this.setState({
              step: c.STEPS.PACKAGES,
            });
          });
        break;
      case c.STEPS.SERVICES:
        // we should definitely have one, but let's be safe
        if (facility) {
          // convert the timestamps to the facility's time zone
          values = {
            ...values,
            ...this.browserToFacilityTZ(facility, values),
          };
        }

        // fetch packages before moving on
        this.fetchPackages(facility, values).then((packages) => {
          // make sure we got some
          if (packages && packages.length > 0) {
            // save them on the state for later
            this.setState({ packages: packages });

            // move to package selection
            this.setState({ step: c.STEPS.PACKAGES });
          }
        });
        break;
      case c.STEPS.PACKAGES:
        // are we prepaying, and is the amount > 0?
        var prepay = false;
        if (values.rateName && this.state.packages) {
          for (var j = 0; j < this.state.packages.length; j++) {
            if (this.state.packages[j].rateName === values.rateName) {
              prepay = this.state.packages[j].prepay && this.state.packages[j].total;

              // there is an edge case; a modification where the prepay amount didn't change
              if (
                this.state.original &&
                this.state.original.prepaid === this.state.packages[j].total
              ) {
                prepay = false;
                this.props.clearValues(['payment']);
              }

              break;
            }
          }
        }
        this.setState({ prepay: prepay });

        // increment the step
        this.setState({ step: this.state.step + 1 });
        break;
      case c.STEPS.VEHICLE:
        // we must be prepaying
        this.setState({
          step: c.STEPS.PAY,
        });
        break;
      default:
        // increment the step
        if (this.state.step < (this.state.prepay ? c.STEPS.PAY : c.STEPS.VEHICLE)) {
          this.setState({ step: this.state.step + 1 });
        }
        break;
    }

    // scroll to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  onPrevious = (values) => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    // if necessary, call the correct handler
    switch (this.state.step) {
      case c.STEPS.PACKAGES:
        // if we don't have services, we skip that screen
        this.setState({
          step:
            this.state.services && this.state.services.length > 1
              ? c.STEPS.SERVICES
              : c.STEPS.TIMES,
        });
        break;
      case c.STEPS.LOCATION + 1:
        // if this is an edit, we can't go back
        if (this.state.original) {
          break;
        } else {
          // fall through
        }

      // eslint-disable-line no-fallthrough
      default:
        // decrement the step
        if (this.state.step > c.STEPS.LOCATION) {
          this.setState({ step: this.state.step - 1 });
        }
        break;
    }

    // scroll to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  onConfirm = (recaptcha, reservation) => {
    // add the location code to the reservation...
    reservation.locationCode = this.state.locationCode;

    // ... and the customer, if he doesn't already have one
    if (reservation.customer && !reservation.customer.locationCode) {
      reservation.customer.locationCode = this.state.locationCode;
    }

    // if the customer location code doesn't match the reservation location code, clear
    // the customer's alternate ID, which will cause the server to ignore any FP information
    if (reservation.customer && reservation.customer.locationCode !== reservation.locationCode) {
      delete reservation.customer.alternateId;
    } else if (!reservation.customer.alternateId) {
      // alternatively, if the reservation customer doesn't have an alternate ID, and if
      // a customer is logged in whose location code matches the reservation location code,
      // we can set the alternate ID
      if (this.props.customer && this.props.customer.locationCode === reservation.locationCode) {
        reservation.customer.alternateId = this.props.customer.alternateId;
      }
    }

    // strip spaces from CC number
    if (
      reservation.payment &&
      reservation.payment.creditCard &&
      reservation.payment.creditCard.number
    ) {
      reservation.payment.creditCard.number = reservation.payment.creditCard.number.replace(
        / /g,
        '',
      );
    }

    // get the reservation's facility
    const facility = this.props.facilities.find((f) => f.id === Number(reservation.facilityId));

    // we should definitely have one, but let's be safe
    if (facility) {
      // convert the reservation's timestamps to the facility's time zone
      reservation = {
        ...reservation,
        ...this.browserToFacilityTZ(facility, reservation),
      };
    }

    // make the call to save
    return this.props
      .save(reservation, this.state.original, recaptcha)
      .then((reservation) => {
        // if we don't have a reservation, the save failed
        if (!reservation) {
          return;
        }

        // if either the new or old (in case of an edit) reservation involved points, we refresh the customer
        if (
          this.props.customer &&
          this.props.facilities &&
          ((this.state.original && this.state.original.fppRedeemed) || reservation.fppRedeemed)
        ) {
          this.props.refreshCustomer(this.props.customer, this.props.facilities);
        }

        // go to the details page
        if (reservation) {
          const facilityId = getIdByFacilityCode(this.props.facilities, reservation.facilityCode);
          this.props.history.push(
            `/reservations/${facilityId}/${reservation.customer.email}/${reservation.id}?new=true`,
            { newBooking: true },
          );
        }
      })
      .catch((e) => {
        // errors thrown potentially contain a step to which the customer should be returned
        if (e.step) {
          this.setState({ step: e.step });
        } else {
          // propagate the error
          throw e;
        }
      });
  };

  renderSummary(progress) {
    // values we'll need
    const start = this.props.start;
    const end = this.props.end;
    const facilities = this.props.facilities;
    const facilityId = this.props.facilityId;
    const prepaid = this.props.prepaid ? this.props.prepaid : 0;
    const payOnExit = this.props.payOnExit ? this.props.payOnExit : 0;

    // find the facility name
    var facilityName = null;
    if (facilities) {
      for (var i = 0; i < facilities.length; i++) {
        if (facilities[i].id === Number(facilityId)) {
          facilityName = facilities[i].name;
          break;
        }
      }
    }

    // date/time format; if times have not been selected, we don't show them
    let format = 'ddd, l';
    if (this.state.step > c.STEPS.TIMES) {
      format += ' h:mm A';
    }

    return (
      <div className="container-fluid png-reservation-book-summary">
        <div className="row">
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            Location
          </div>
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            Check In
          </div>
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            Check Out
          </div>
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            {prepaid === 0 && payOnExit === 0 && 'Total'}
            {prepaid > 0 && 'Pay Now'}
            {prepaid === 0 && payOnExit > 0 && 'Pay on Exit'}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">Location: </span>
            {facilityName && facilityName && <span>{facilityName}</span>}
          </div>
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">Check In: </span>
            {this.state.step > c.STEPS.DATES && start && (
              <span>{moment.unix(start).format(format)}</span>
            )}
          </div>
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">Check Out: </span>
            {this.state.step > c.STEPS.DATES && end && (
              <span>{moment.unix(end).format(format)}</span>
            )}
          </div>
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">
              {prepaid === 0 && payOnExit === 0 && 'Total: '}
              {prepaid > 0 && 'Pay Now: '}
              {prepaid === 0 && payOnExit > 0 && 'Pay on Exit: '}
            </span>
            {this.state.step >= c.STEPS.PACKAGES && (
              <span>
                {prepaid > 0 && <span>${prepaid.toFixed(2)}</span>}
                {prepaid === 0 && payOnExit > 0 && <span>${payOnExit.toFixed(2)}</span>}
              </span>
            )}
          </div>
        </div>

        {/* progress bar */}
        <div className="progress png-reservation-book-progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: progress + '%' }}
            aria-valuenow={progress / 100}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    );
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // error other than "not found"?
    if (this.state.fetchError && !this.state.notFound) {
      return <Retry onRefresh={() => this.loadReservation(this.props)} />;
    }

    // jumping in?
    else if (this.state.jumpIn) {
      return (
        <div className="container">
          <div className="row">
            <div className="col png-page-header">New Reservation</div>
          </div>
          <div className="row">
            <div className="col-12 text-center png-content-loading">
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    // still loading?
    else if (this.state.loading) {
      return (
        <div className="container">
          <div className="row">
            <div className="col png-page-header">Edit Reservation</div>
          </div>
          <div className="row">
            <div className="col-12 text-center png-content-loading">
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    // step to header mappings
    const headers = [];
    headers[c.STEPS.LOCATION] = 'Select a location';
    headers[c.STEPS.DATES] = 'Select check in and check out dates';
    headers[c.STEPS.TIMES] = 'Select check in and check out times';
    headers[c.STEPS.SERVICES] = 'How else can we serve you?';
    headers[c.STEPS.PACKAGES] = 'Select a rate';
    headers[c.STEPS.CUSTOMER] = 'Tell us about yourself';
    headers[c.STEPS.VEHICLE] = 'Tell us about your vehicle (optional)';
    headers[c.STEPS.PAY] = 'Provide payment details';
    headers[c.STEPS.CONFIRM] = 'Please confirm your reservation';

    // current progress
    const lastStep = this.state.prepay ? c.STEPS.PAY : c.STEPS.VEHICLE;
    const progress = (() => {
      switch (this.state.step) {
        case c.STEPS.LOCATION:
          return 0;
        case lastStep:
          return 100;
        default:
          return (100 / (lastStep - 1)) * (this.state.step - 1);
      }
    })();

    // cancellation function
    const cancel = () => {
      // clean up the state
      this.setState({ original: null });

      // invoke the callback
      this.props.cancel();
    };

    // render
    return (
      <div>
        {!this.props.facilities && (
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h2>Unavailable</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span>
                  Reservation booking is temporarily unavailable. Rest assured that we are working
                  hard to resolve this problem. Please check back later!
                </span>
              </div>
            </div>
          </div>
        )}

        {this.props.facilities && (
          <div className="container-fluid png-reservation-book">
            {/* notice */}
            {this.state.notFound && (
              <div className="row">
                <div className="col">
                  <div className="alert alert-info alert-dismissible fade show" role="alert">
                    We couldn't find the requested reservation. Why not create a new one?
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* page header */}
            <div className="row">
              <div className="col png-page-header">
                {this.state.original && <span>Edit</span>}
                {!this.state.original && <span>New</span>} Reservation
              </div>
            </div>

            {/* step header */}
            <div className="row">
              <div className="col">
                <h2>{headers[this.state.step]}</h2>
              </div>
            </div>

            {/* summary */}
            {this.renderSummary(progress)}

            {/* content */}
            <div className="modal-body">
              {/* errors */}
              {this.props.error && (
                <div className="has-error">
                  <div className="png-form-error">{this.props.error}</div>
                </div>
              )}

              {/* select location */}
              {this.state.step === c.STEPS.LOCATION && (
                <LocationForm
                  facilities={this.props.facilities}
                  customer={this.props.customer}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* dates */}
              {this.state.step === c.STEPS.DATES && (
                <DatesForm
                  facilities={this.props.facilities}
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* times */}
              {this.state.step === c.STEPS.TIMES && (
                <TimesForm
                  facilities={this.props.facilities}
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* services */}
              {this.state.step === c.STEPS.SERVICES && (
                <ServicesForm
                  services={this.state.services}
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* packages */}
              {this.state.step === c.STEPS.PACKAGES && (
                <PackagesForm
                  facilities={this.props.facilities}
                  original={this.state.original}
                  locationCode={this.state.locationCode}
                  customer={this.props.customer}
                  services={this.state.services}
                  packages={this.state.packages}
                  onPointChange={(values) => {
                    // get the facility
                    const facility = this.props.facilities.find(
                      (f) => f.id === Number(values.facilityId),
                    );

                    // re-fetch packages
                    return this.fetchPackages(facility, {
                      ...values,
                      customer: this.props.customer,
                    }).then((packages) => {
                      // make sure we got some
                      if (packages && packages.length > 0) {
                        // save them on the state for later
                        this.setState({ packages: packages });
                      }

                      return packages;
                    });
                  }}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* customer */}
              {this.state.step === c.STEPS.CUSTOMER && (
                <CustomerForm
                  customer={this.props.customer}
                  original={this.state.original}
                  prepay={this.state.prepay}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* vehicles */}
              {this.state.step === c.STEPS.VEHICLE && (
                <VehicleForm
                  customer={this.props.customer}
                  original={this.state.original}
                  prepay={this.state.prepay}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) =>
                    this.state.prepay ? this.onNext(values) : this.onConfirm(this.recaptcha, values)
                  }
                />
              )}

              {/* pay */}
              {this.state.step === c.STEPS.PAY && (
                <PayForm
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onConfirm(this.recaptcha, values)}
                />
              )}

              {/* confirm */}
              {this.state.step === c.STEPS.CONFIRM && (
                <ConfirmForm
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onConfirm(this.recaptcha, values)}
                />
              )}

              {/* fee disclaimer */}
              <div className="row">
                <div className="col text-center png-reservation-book-disclaimer">
                  <FontAwesomeIcon icon="info-circle" /> Taxes & Fees includes applicable taxes,
                  surcharges and online reservation fee.
                </div>
              </div>

              {/* FP login */}
              {!this.props.customer && this.state.step <= c.STEPS.PACKAGES && (
                <div
                  className="alert alert-primary alert-dismissible fade show png-reservation-book-login"
                  role="alert"
                >
                  Want to earn some points on this stay?{' '}
                  <span
                    className="png-inline-link"
                    onClick={() => {
                      this.props.fppModal();
                    }}
                  >
                    Login
                  </span>{' '}
                  to your Frequent Parker Program account!
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // status
  status: state.context.status,

  // the logged in customer, if there is one
  customer: state.context.customer,

  // facilities
  facilities: state.context.facilities,

  // all current form values
  currentValues:
    state.form && state.form.reservationForm ? state.form.reservationForm.values : undefined,

  // individual form values necessary for summary
  facilityId: formValueSelector(ownProps.formName)(state, 'facilityId'),
  start: formValueSelector(ownProps.formName)(state, 'start'),
  end: formValueSelector(ownProps.formName)(state, 'end'),
  selectedPackage: formValueSelector(ownProps.formName)(state, 'rateName'),
  prepaid: formValueSelector(ownProps.formName)(state, 'prepaid'),
  payOnExit: formValueSelector(ownProps.formName)(state, 'payOnExit'),
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // parking provider disabled
  parkingProviderDisabled: () => {
    // show an alert
    dispatch(
      addAlert(
        'warning',
        'Our online reservation system is undergoing maintenance. Please call ' +
          'one of our lots directly to make a reservation.',
        8000,
      ),
    );

    // go home
    dispatch(navigate('home'));
  },

  // clears specific values on the form
  clearValues: (fields) => {
    fields.forEach((field) => {
      dispatch(change(ownProps.formName, field, null));
    });
  },

  resetForm: () => {
    // reset the form...
    dispatch(reset(ownProps.formName));

    // ... and any submission errors
    dispatch(clearSubmitErrors(ownProps.formName));

    // also clear the initial values
    dispatch(
      initialize(
        ownProps.formName,
        {},
        {
          keepDirty: false,
          updateUnregisteredFields: true,
          keepValues: false,
        },
      ),
    );
  },

  // sets the facility on the form
  setFacility: (facilityId) => {
    if (facilityId) {
      // set the ID
      dispatch(change(ownProps.formName, 'facilityId', Number(facilityId)));
    } else {
      // clear any old ID
      dispatch(change(ownProps.formName, 'facilityId', null));
    }
  },

  // sets the number of FPP redeemed on the form
  setFPPRedeemed: (fppRedeemed) => {
    dispatch(change(ownProps.formName, 'fppRedeemed', fppRedeemed));
  },

  // sets the dates on the form
  setDates: (start, end) => {
    dispatch(change(ownProps.formName, 'start', start));
    dispatch(change(ownProps.formName, 'end', end));
  },

  // sets the customer on the form
  setCustomer: (customer) => {
    dispatch(change(ownProps.formName, 'customer', customer));
  },

  // find a reservation
  fetchReservation: (facilityId, email, reservationId, customer) => {
    return dispatch(fetchReservation(facilityId, email, reservationId)).then((reservation) => {
      // initialize the form with the customer's information, overridden by the reservation details
      dispatch(
        initialize(
          ownProps.formName,
          {
            facilityId: facilityId,
            ...reservation,
            customer: {
              ...customer,
              ...reservation.customer,
            },

            // because we don't net out payments on reservation modifications, we need
            // to prime the UI to prompt for full payment; this amount will be updated
            // as appropriate if the user selects a new package
            payment: { amount: reservation.prepaid ? reservation.prepaid : undefined },
          },
          {
            keepDirty: false,
            updateUnregisteredFields: true,
            keepValues: false,
          },
        ),
      );

      return reservation;
    });
  },

  // fetches available services
  fetchServices: (facilityId, start, end) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // fetch the services
    return dispatch(fetchServices(facilityId, start, end))
      .catch((e) => {
        // send an SOS
        dispatch(sendSOS('Error fetching services', e));

        // log it
        console.error('Error fetching services; this is bad', e);
        return null;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // fetches available packages
  fetchPackages: (facilityId, start, end, customerId, services, fppRedeemed) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // fetch the packages (quotes)
    return dispatch(fetchQuotes(facilityId, start, end, customerId, services, fppRedeemed))
      .then((packages) => {
        // did we find some?
        if (!packages || packages.length === 0) {
          // toss an alert
          dispatch(addAlert('info', 'Sorry, the facility is sold out for the chosen dates.', 8000));
        }

        return packages;
      })
      .catch((e) => {
        // there are a few legit errors
        if (e.code && e.code === 22) {
          // toss an alert
          dispatch(addAlert('info', 'Sorry, the facility is sold out for the chosen dates.', 8000));
        } else if (e.code && e.code === 6018) {
          // toss an alert
          dispatch(
            addAlert(
              'info',
              'Sorry, the facility does not accept reservations of that length.',
              4000,
            ),
          );
        } else {
          // toss an alert
          dispatch(
            addAlert(
              'error',
              'We ran into a problem. Sorry about that! Please wait a minute and try again. ' +
                'If the problem persists, please contact support.',
              8000,
            ),
          );

          // send an SOS
          dispatch(sendSOS('Error fetching packages', e));

          // log it
          console.error('Error fetching packages; this is bad', e);
        }
        return null;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // cancels the workflow
  cancel: () => {
    // reset the form...
    dispatch(reset(ownProps.formName));

    // ... and any submission errors
    dispatch(clearSubmitErrors(ownProps.formName));

    // also clear the initial values
    dispatch(
      initialize(
        ownProps.formName,
        {},
        {
          keepDirty: false,
          updateUnregisteredFields: true,
          keepValues: false,
        },
      ),
    );

    // finally, navigate to the "new reservation" url
    dispatch(navigate('newReservation'));
  },

  // saves a reservation
  save: (toSave, original, recaptcha) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // add or update
    const saveReservation = original ? updateReservation : addReservation;

    // if update, cannot change a few things
    if (original) {
      toSave.id = original.id;
      toSave.locationCode = original.locationCode;
    }

    // if the vehicle doesn't have the minimum number of fields, get rid of it
    if (
      toSave.vehicle &&
      (!toSave.vehicle.state ||
        toSave.vehicle.state.length === 0 ||
        !toSave.vehicle.license ||
        toSave.vehicle.license.length === 0)
    ) {
      delete toSave.vehicle;
    }

    // prepaid?
    if (toSave.payment && toSave.payment.amount && toSave.payment.amount > 0) {
      // via credit card?
      if (toSave.payment.type === 'creditCard') {
        // copy customer's address to the credit card
        if (toSave.payment.creditCard && toSave.customer) {
          toSave.payment.creditCard.address = toSave.customer.address;
        }

        // we sometimes end up with extra spaces in card data (autocomplete?)
        toSave.payment.creditCard.issuer = toSave.payment.creditCard?.issuer.trim();
        toSave.payment.creditCard.number = toSave.payment.creditCard?.number.trim();
        toSave.payment.creditCard.expiration = toSave.payment.creditCard?.expiration.trim();
        toSave.payment.creditCard.cvv = toSave.payment.creditCard?.cvv.trim();

        // and somehow people are able to get 4 digit years through
        if (toSave.payment.creditCard.expiration?.length === 6) {
          toSave.payment.creditCard.expiration =
            toSave.payment.creditCard.expiration.substring(0, 2) +
            toSave.payment.creditCard.expiration.substring(4, 6);
        }
      } else {
        delete toSave.payment.creditCard;
      }
    } else {
      delete toSave.payment;
    }

    // clients cannot set notes
    delete toSave.notes;

    // get the reCAPTCHA token and submit
    return recaptcha
      .execute('customerProfile')
      .then((token) => {
        // make the call to save
        return dispatch(saveReservation(toSave.facilityId, toSave, token)).then((reservation) => {
          // clear the form and reset all initial values
          dispatch(reset(ownProps.formName));
          dispatch(clearSubmitErrors(ownProps.formName));
          dispatch(
            initialize(
              ownProps.formName,
              {},
              {
                keepDirty: false,
                updateUnregisteredFields: true,
                keepValues: false,
              },
            ),
          );

          // show a success message
          dispatch(
            addAlert(
              'success',
              !original
                ? `Your reservation has been created! It is number ${reservation.id}. Check your email for a message confirming your reservation. See you at the lot!`
                : original.id === reservation.id
                ? `Reservation ${reservation.id} has been updated. Check your email for a message confirming the ` +
                  `details. See you at the lot!`
                : `Reservation ${original.id} has been canceled and ${reservation.id} has been created. Check your email for a message confirming the ` +
                  `details. See you at the lot!`,
              !original ? 8000 : original.id === reservation.id ? 12000 : 3000,
            ),
          );

          // return it
          return reservation;
        });
      })
      .catch((e) => {
        // we may send the user back to a previous step
        let step = undefined;

        // we understand a few different errors
        if (e.code) {
          let message = undefined;
          switch (e.code) {
            case 1:
              message = `Something isn't quite right in your reservation. Please double-check everything and try again.`;
              step = c.STEPS.DATES;
              break;
            case 6001:
              message = `You cannot modify a reservation that has already been redeemed.`;
              dispatch(addAlert('error', message, 8000));
              dispatch(navigate('home'));
              return;
            case 6002:
              message = `You cannot modify a reservation that has already been cancelled.`;
              dispatch(addAlert('error', message, 8000));
              dispatch(navigate('home'));
              return;
            case 6003:
              message =
                'You already have a reservation that overlaps these dates. Please cancel that reservation first or choose different dates.';
              step = c.STEPS.DATES;
              break;
            case 6007:
              message = 'Payment is required.';
              step = c.STEPS.PAY;
              break;
            case 6009:
              // special case: show a message and then go home
              message =
                'This is embarrassing. Your original reservation was canceled, but we failed to refund your money. ' +
                'The system has already contacted our customer support staff and explained the situation. ' +
                'They will take care of the refund. Your new reservation was not booked. Since your old reservation ' +
                'was cancelled, you can simply book a new one.';
              dispatch(addAlert('error', message, 15000));
              dispatch(navigate('home'));
              return;
            case 6012:
              message = 'You do not have enough points to complete the reservation.';
              step = c.STEPS.PACKAGES;
              break;
            case 6014:
              message = 'The selected rate is no longer available. Please choose another.';
              step = c.STEPS.PACKAGES;
              break;
            case 6015:
              message =
                'We were not able to process payment using the provided card. Please try another.';
              step = c.STEPS.PAY;
              break;
            case 6019:
              message =
                'We were unable to complete your reservation. This facility is sold out for the chosen dates.';
              step = c.STEPS.DATES;
              break;
            case 8000:
              message = 'The payment card you provided is invalid. Please try another.';
              step = c.STEPS.PAY;
              break;
            case 8001:
              message = 'The payment card you provided is invalid. Please try another.';
              step = c.STEPS.PAY;
              break;
            case 8002:
              message = 'The payment card you provided was declined. Please try another.';
              step = c.STEPS.PAY;
              break;
            case 8004:
              message =
                'The security code you provided with your payment card is invalid. Please check it.';
              step = c.STEPS.PAY;
              break;
            case 8005:
              message = 'Please enter details for your credit card.';
              step = c.STEPS.PAY;
              break;
            case 8006:
              message = `We failed to process your payment. Please try again, and if that doesn't work, try a different payment type.`;
              step = c.STEPS.PAY;
              break;
            case 8007:
              message =
                'The address you provided with your payment card is invalid. Please check it.';
              step = c.STEPS.PAY;
              break;
            default:
              // will be handled below
              break;
          }

          // did we recognize it?
          if (message) {
            dispatch(addAlert('error', message, 8000));
            const error = new Error(
              `An error occurred, and the user should be returned to step ${step}`,
            );
            error.step = step;
            throw error;
          }
        }

        // toss a generic alert
        dispatch(
          addAlert(
            'error',
            'We ran into a problem saving your reservation. Sorry about that! Please ' +
              ' wait a minute and try again. If the problem persists, please contact support.',
            8000,
          ),
        );

        // send an SOS
        dispatch(sendSOS(`Error saving reservation: ${JSON.stringify(toSave)}`, e));

        // log it
        console.error('Error saving reservation; this is bad', e);
        return null;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // refreshes the logged in customer
  refreshCustomer: (customer, facilities) => {
    // if not in the sandbox, refresh the customer
    if (customer && facilities) {
      const facilityId = getIdByNetParkCode(facilities, customer.locationCode);
      if (facilityId > 0) {
        dispatch(fetchCustomer(facilityId, customer.email, customer.alternateId))
          .then((customer) => {
            // refresh it on the context
            dispatch(storeOnContext('customer', customer));
          })
          .catch((e) => {
            // eat it
            console.error('Error refreshing customer', e);
          });
      }
    }
  },

  // opens FPP modal
  fppModal: () => {
    dispatch(showFPPModal());
  },

  // new reservation
  newReservation: () => {
    dispatch(navigate('newReservation'));
  },
});

// turn this into a container component
BookReservation = withRouter(connect(mapStateToProps, mapDispatchToProps)(BookReservation));

// set default props
BookReservation.defaultProps = {
  formName: 'reservationForm',
};

export default BookReservation;
