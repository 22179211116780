import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import {
  clearSubmitErrors,
  Field,
  FieldArray,
  Form,
  formValueSelector,
  reduxForm,
} from 'redux-form';

import { BaseEditForm, processErrors } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { getIdByNetParkCode } from 'entities/Facility/util';
import { renderEnhancedField } from 'util/form/renderers';
import { formatPhone, formatZip } from 'util/formatters';
import './styles.scss';

/* Customer profile form. */
class CustomerForm extends BaseEditForm {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for reCAPTCHA
    this.recaptcha = null;

    // for vehicle management
    this.state = {
      ...this.state,
      toRemove: [],
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // load reCAPTCHA
    load(process.env.REACT_APP_RECAPTCHA_KEY, {
      explicitRenderParameters: {
        badge: 'bottomleft',
      },
    })
      .then((recaptcha) => {
        // capture the instance
        this.recaptcha = recaptcha;

        // show the badge
        console.debug('Showing reCAPTCHA badge');
        $('.grecaptcha-badge').removeClass('png-hidden');
      })
      .catch((e) => {
        console.error('Failed to load reCAPTCHA', e);
      });
  }

  componentWillUnmount() {
    // parent, for lifecycle logging
    super.componentWillUnmount();

    // hide the badge
    console.debug('Hiding reCAPTCHA badge');
    $('.grecaptcha-badge').addClass('png-hidden');
  }

  submit = (recaptcha, values, dispatch) => {
    // handle vehicle removal for updates
    if (values.id && this.state.toRemove) {
      // only remove vehicles that have a state and license
      var remove = this.state.toRemove.filter((vehicle) => vehicle.state && vehicle.license);

      // set the remove flag on all of them
      remove = remove.map((vehicle, _) => {
        vehicle.remove = true;
        return vehicle;
      });

      // set them back on the customer
      if (values.vehicles) {
        values.vehicles = values.vehicles.concat(remove);
      } else {
        values.vehicles = remove;
      }
    }

    // get the reCAPTCHA token and submit
    return recaptcha
      .execute('customerProfile')
      .then((token) => {
        // set the token on the form
        values.challengeResponse = token;

        // make the call to save
        return this.props
          .onSubmit(values)
          .then((result) => {
            // disable editing and clear remove list
            this.setState({ currentlyEditing: null, toRemove: [] });

            // propagate the result
            return result;
          })
          .catch((e) => {
            // we need to re-remove the removed vehicles
            if (values.vehicles) {
              values.vehicles = values.vehicles.filter((vehicle) => !vehicle.remove);
            }

            // process the error(s); this will invoke the fail handler
            processErrors(
              e,
              dispatch,
              'We ran into a problem! Please review your information and try again.',

              // handle errors specific to this form
              (error) => {
                switch (error.code) {
                  // customer email already exists
                  case 7001:
                    return {
                      [error.field]:
                        'This email address is already associated with a Frequent Parker account',
                      fieldError: true,
                    };
                  default:
                    break;
                }

                return null;
              },
            );
          });
      })
      .catch((e) => {
        // if we got a SubmissionError, we've already processed it
        if (e && e.name === 'SubmissionError') {
          throw e;
        }

        // process the error(s); this will invoke the fail handler
        console.error('Failed to get reCAPTCHA token', e);
        processErrors(
          e,
          dispatch,
          `We ran into a technical problem! It's not your fault. You can try again, but we can't guarantee it will work.`,
          null,
        );
        return null;
      });
  };

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if we don't have an entity, this is an addition
    if (!this.props.entity) {
      // new object
      this.setState({ currentlyEditing: 'NEW' });
    } else if (this.props.entity.id !== this.state.currentlyEditing) {
      // the object changed
      this.setState({ currentlyEditing: null });
    }
  }

  // bind address auto-completion
  addressAutoComplete = () => ({
    keyField: 'address.street',
    addressFields: {
      street: 'address.street',
      street2: 'address.street2',
      city: 'address.city',
      state: 'address.state',
      zip: 'address.zip',
    },
  });

  renderVehicles = ({ fields, addition, currentlyEditing }) => (
    <div>
      {/* vehicle information */}
      <div className="form-row">
        <div className="col">
          <h2>
            Your Vehicle
            {addition ? '' : 's'}{' '}
            {currentlyEditing && (!addition || fields.length < 1) && !this.props.submitting && (
              <FontAwesomeIcon
                icon="plus"
                className="fa-spinner png-half-spin png-clickable"
                onClick={() => fields.push({})}
              />
            )}
          </h2>
          <p>
            {!addition && fields.length === 0 && (
              <span>You currently have no vehicles associated to your profile. </span>
            )}
            Adding a vehicle to your profile is optional. If you choose to add one, you'll be able
            to quickly select that vehicle when booking a reservation.
          </p>
        </div>
      </div>

      {/* vehicle */}
      {fields.map((vehicle, index) => (
        <div key={index} className="png-customer-form-vehicle">
          {/* remove vehicle */}
          <div className="form-row">
            <div className="col png-customer-form-vehicle">
              <h3>
                <div className="png-customer-form-vehicle-name">{`Vehicle ${index + 1} `}</div>
              </h3>
            </div>
            {currentlyEditing && !this.props.submitting && (
              <div className="col png-customer-form-vehicle-remove">
                <h3>
                  <FontAwesomeIcon
                    icon="times"
                    className="fa-spinner png-half-spin png-clickable"
                    onClick={() => {
                      this.setState({
                        toRemove: [...this.state.toRemove, this.props.currentVehicles[index]],
                      });
                      fields.remove(index);
                    }}
                  />
                </h3>
              </div>
            )}
          </div>

          {/* make/model/color */}
          <div className="form-row">
            <div className="form-group col-sm-4 has-error">
              <Field
                type="text"
                label="Make"
                name={`${vehicle}.make`}
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                placeholder="Make"
                tooltip={`The make of this vehicle; 'Ford', for example`}
                maxLength="32"
                disabled={!this.state.currentlyEditing || this.props.submitting}
              />
            </div>
            <div className="form-group col-sm-4 has-error">
              <Field
                type="text"
                label="Model"
                name={`${vehicle}.model`}
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                placeholder="Make"
                tooltip={`The model of this vehicle; 'F-150', for example`}
                maxLength="32"
                disabled={!this.state.currentlyEditing || this.props.submitting}
              />
            </div>
            <div className="form-group col-sm-4 has-error">
              <Field
                type="text"
                label="Color"
                name={`${vehicle}.color`}
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                placeholder="Color"
                tooltip={`The color of this vehicle`}
                maxLength="32"
                disabled={!this.state.currentlyEditing || this.props.submitting}
              />
            </div>
          </div>

          {/* state/license */}
          <div className="form-row">
            <div className="form-group col-sm-5 has-error">
              <Field
                type="select"
                label="State/Province"
                name={`${vehicle}.state`}
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                placeholder="State/Province"
                tooltip={`The state or province in which the vehicle is registered`}
                required={true}
                disabled={
                  !this.state.currentlyEditing ||
                  this.props.submitting ||
                  (this.props.currentVehicles &&
                    this.props.currentVehicles.length > index &&
                    this.props.currentVehicles[index].id)
                }
              >
                {this.renderStateOptions()}
              </Field>
            </div>
            <div className="form-group col-sm-3 has-error">
              <Field
                type="text"
                label="License"
                name={`${vehicle}.license`}
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                normalize={(value) => {
                  // only allow alphanumeric, spaces, and dashes
                  return value.replace(/[^a-zA-Z0-9 -]/g, '').toUpperCase();
                }}
                placeholder="License"
                tooltip={`The license of this vehicle`}
                maxLength="8"
                required={true}
                disabled={
                  !this.state.currentlyEditing ||
                  this.props.submitting ||
                  (this.props.currentVehicles &&
                    this.props.currentVehicles.length > index &&
                    this.props.currentVehicles[index].id)
                }
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  render() {
    // parent, for lifecycle logging
    super.render();

    // if we don't have an entity, this is an addition
    let addition = false;
    if (!this.props.entity) {
      addition = true;
    } else {
      // we need to note the vehicles that are already set
      if (this.props.entity.vehicles) {
        // it's rare, but it's possible to end up with null vehicles
        this.props.entity.vehicles = this.props.entity.vehicles.filter(
          (vehicle) => vehicle != null,
        );

        // if not already present, add an ID to each vehicle
        this.props.entity.vehicles = this.props.entity.vehicles.map((vehicle, i) => {
          if (!vehicle.id) {
            vehicle.id = (i + 1).toString();
          }
          return vehicle;
        });
      }
    }

    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit((values, dispatch) =>
          this.submit(this.recaptcha, values, dispatch),
        )}
        className="png-form png-customer-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && <div className="png-form-error">{this.props.error}</div>}

        {/* FP information */}
        <div className="form-row">
          <div className="col">
            <h2>Frequent Parker Information</h2>
          </div>
        </div>

        {/* number and primary location */}
        <div className="form-row">
          {!addition && (
            <div className="form-group col-md-4 has-error">
              <Field
                type="text"
                label="Frequent Parker #"
                name="alternateId"
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                placeholder="Frequent Parker #"
                tooltip={`Your frequent parker number; this cannot be changed`}
                maxLength="9"
                disabled={true}
              />
            </div>
          )}
          <div className="form-group col-md-8 has-error">
            <Field
              type="select"
              label="Primary Parking Location"
              name="locationCode"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Primary Parking Location"
              tooltip={
                `Your primary parking location; your account will be tied to this location, ` +
                `and this is where you can earn and spend your frequent parker points`
              }
              required={true}
              disabled={this.props.submitting || !addition}
              onChange={(e) => {
                // translate the netParkCode to an ID
                const id = getIdByNetParkCode(this.props.facilities, Number(e.target.value));

                // capture the selected facility in local storage
                localStorage.setItem('selectedFacility', id);
              }}
              normalize={(value) => Number(value)}
            >
              {this.renderFacilityOptions(this.props.facilities, false)}
            </Field>
          </div>
        </div>

        {/* customer information */}
        <div className="form-row">
          <div className="col">
            <h2>Your Information</h2>
          </div>
        </div>

        {/* first/last name */}
        <div className="form-row">
          {/* first name */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="text"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="First Name"
              tooltip={`Your first name`}
              maxLength="32"
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
          </div>

          {/* last name */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="text"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Last Name"
              tooltip={`Your last name`}
              maxLength="64"
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
            {!addition && this.props.currentLastName !== this.props.entity.lastName && (
              <span className="png-field-warning">
                If you change your last name, you will no longer see reservations made under the
                previous name in your reservation list.
              </span>
            )}
          </div>
        </div>

        {/* address lines */}
        <div className="form-row">
          <div className="form-group col-md-6 has-error">
            <Field
              type="text"
              label="Address Line 1"
              name="address.street"
              autoComplete="address-line1"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Address Line 1"
              tooltip={`Your street address`}
              maxLength="255"
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
          </div>
          <div className="form-group col-md-6 has-error">
            <Field
              type="text"
              label="Address Line 2"
              name="address.street2"
              autoComplete="address-line2"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Address Line 2 (Optional)"
              tooltip={`Any supplementary address information necessary to find you, like a suite or apartment number`}
              maxLength="64"
              required={false}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
          </div>
        </div>

        {/* city/state/zip */}
        <div className="form-row">
          {/* city */}
          <div className="form-group col-sm-4 has-error">
            <Field
              type="text"
              label="City"
              name="address.city"
              autoComplete="address-level2"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="City"
              tooltip={`Your city`}
              maxLength="64"
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
          </div>

          {/* state */}
          <div className="form-group col-sm-5 has-error">
            <Field
              type="select"
              label="State/Province"
              name="address.state"
              autoComplete="address-level1"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="State/Province"
              tooltip={`Your state or province`}
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            >
              {this.renderStateOptions()}
            </Field>
          </div>

          {/* zip */}
          <div className="form-group col-sm-3 has-error">
            <Field
              type="text"
              label="Postal Code"
              name="address.zip"
              autoComplete="postal-code"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              minLength="5"
              maxLength="10"
              pattern="^(\d{5})$|^(\d{5}-\d{4})$|^([A-Z]\d{1}[A-Z]-\d{1}[A-Z]\d{1})$"
              placeholder="Postal Code"
              tooltip={`Your postal code`}
              format={formatZip}
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
          </div>
        </div>

        {/* phone numbers */}
        <div className="form-row">
          {/* personal phone */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="tel"
              label="Personal Phone"
              name="personalPhone"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              autoComplete="tel-national"
              component={renderEnhancedField}
              placeholder="Personal Phone"
              tooltip={`Your personal phone number`}
              format={formatPhone}
              minLength="12"
              maxLength="12"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
          </div>

          {/* work phone */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="tel"
              label="Work Phone"
              name="workPhone"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              autoComplete="tel-national"
              component={renderEnhancedField}
              placeholder="Work Phone (Optional)"
              tooltip={`Your work phone number`}
              format={formatPhone}
              minLength="12"
              maxLength="12"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required={false}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
          </div>
        </div>

        {/* email address */}
        <div className="form-row">
          <div className="form-group col has-error">
            <Field
              type="email"
              label="Email Address"
              name="email"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              autoComplete="email"
              component={renderEnhancedField}
              placeholder="Email Address"
              tooltip={`Your email address`}
              maxLength="128"
              required={true}
              disabled={!this.state.currentlyEditing || this.props.submitting}
            />
            {!addition && this.props.currentEmail !== this.props.entity.email && (
              <span className="png-field-warning">
                If you change your email address, you will no longer be able to access reservations
                made under the previous address. You can still redeem any outstanding reservations,
                though.
              </span>
            )}
          </div>
        </div>

        {/* vehicles */}
        <FieldArray
          name="vehicles"
          addition={addition}
          component={this.renderVehicles}
          currentlyEditing={this.state.currentlyEditing /* to force re-render on edit */}
        />

        {/* edit toggle: this is to make sure the user really has to think about editing */}
        {!this.state.currentlyEditing && !addition && (
          <div className="form-row">
            <div className="form-group col text-center">
              <button
                type="button"
                onClick={() => this.setState({ currentlyEditing: this.props.entity.id })}
                className="btn btn-primary btn-lg"
              >
                Edit Profile
              </button>
            </div>
          </div>
        )}

        {/* cancel/save buttons */}
        {this.state.currentlyEditing && (
          <div className="form-row">
            {!addition && (
              <div className="form-group col-6 text-left">
                <button
                  type="button"
                  disabled={this.props.submitting}
                  onClick={() => {
                    this.props.reset();
                    this.setState({ currentlyEditing: null });
                  }}
                  className="btn btn-primary btn-lg"
                >
                  Cancel
                </button>
              </div>
            )}

            <div
              className={`form-group col-${addition ? '12' : '6'} text-${
                addition ? 'center' : 'right'
              }`}
            >
              <button
                type="submit"
                disabled={
                  !this.props.submitFailed &&
                  (!this.state.currentlyEditing ||
                    this.props.invalid ||
                    !this.state.htmlValid ||
                    this.props.pristine ||
                    this.props.submitting)
                }
                className="btn btn-primary btn-lg"
              >
                {addition && <span>Join</span>}
                {!addition && <span>Save</span>}
              </button>
            </div>
          </div>
        )}
      </Form>
    );
  }
}

// decorate with reduxForm()
CustomerForm = reduxForm({
  // force reinitialization when the initial values change
  enableReinitialize: true,

  // clear form-level errors on change
  onChange: (_values, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(CustomerForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // pull initial values from the passed in entity
  initialValues: ownProps.entity
    ? {
        ...ownProps.entity,

        // uppercase the FP#
        alternateId: ownProps.entity.alternateId
          ? ownProps.entity.alternateId.toUpperCase()
          : ownProps.entity.alternateId,
      }
    : {
        // try to default the location
        locationCode: ((facilityId) => {
          // make sure we recognize the facility
          const facility =
            facilityId && ownProps.facilities.find((f) => f.id === Number(facilityId));
          return facility ? facility.netParkCode : null;
        })(localStorage.getItem('selectedFacility')),
      },

  // addition or update?
  addition: !ownProps.entity,

  // number of phone numbers; we want a change in this count to force a re-render
  currentVehicles: formValueSelector(ownProps.form)(state, 'vehicles'),

  // current email address
  currentEmail: formValueSelector(ownProps.form)(state, 'email'),

  // current last name
  currentLastName: formValueSelector(ownProps.form)(state, 'lastName'),
});

// turn this presentation component into a container component
CustomerForm = withRouter(connect(mapStateToProps)(CustomerForm));

// set default props
CustomerForm.defaultProps = {
  form: 'customerForm',
};

export default CustomerForm;
