import $ from 'jquery';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import Content from 'components/Content';
import { getMapPin, getMapStyles } from 'util/index';
import './styles.scss';

/* A map that shows all locations. */
export class OverviewMap extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // we have a unique use case here where we care about the window width,
    // but we only care what band it falls within, not the exact value
    const widthToBand = (width) =>
      $(window).width() > 680
        ? 'large'
        : $(window).width() >= 576
        ? 'medium'
        : $(window).width() >= 375
        ? 'small'
        : 'tiny';
    this.state = {
      width: widthToBand(),
    };
    window.addEventListener('resize', () => this.setState({ width: widthToBand() }));
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // if we have facilities...
    if (this.props.facilities && this.props.facilities.length > 0) {
      // map styles
      const mapStyles = getMapStyles(true);

      // zoom based on the width band
      var zoom =
        this.state.width === 'large'
          ? 4.8
          : this.state.width === 'medium'
          ? 4.2
          : this.state.width === 'small'
          ? 3.8
          : 3.2;

      // the map, centered at center point of all facilities
      const lat =
        this.props.facilities.map((f) => f.location.latitude).reduce((acc, val) => acc + val, 0) /
        this.props.facilities.length;
      const lng =
        this.props.facilities.map((f) => f.location.longitude).reduce((acc, val) => acc + val, 0) /
        this.props.facilities.length;
      var FacilitiesMap = withScriptjs(
        withGoogleMap((props) => (
          <GoogleMap
            ref={props.onMapLoad}
            defaultZoom={zoom}
            defaultCenter={{ lat, lng }}
            onClick={props.onMapClick}
            defaultOptions={{
              styles: mapStyles,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              fullscreenControl: false,
              streetViewControl: false,
            }}
          >
            {props.facilities.map((facility) => (
              <MarkerWithLabel
                key={facility.marker.key}
                {...facility.marker}
                onClick={() => {
                  this.props.history.push(`/locations/${facility.marker.key}`);
                }}
              >
                <div>{facility.label && facility.label}</div>
              </MarkerWithLabel>
            ))}
          </GoogleMap>
        )),
      );

      // add the facilities as markers
      const markers = [];
      for (var i = 0; i < this.props.facilities.length; i++) {
        // get the information for the marker
        const facility = this.props.facilities[i];
        const id = facility.id;
        const lat = facility.location.latitude;
        const lng = facility.location.longitude;

        // build and add the marker
        markers.push({
          marker: {
            key: id,
            title: facility.name,
            labelClass: 'png-locations-map-label-primary',
            labelAnchor: { x: 0, y: 0 },
            position: {
              lat,
              lng,
            },
            icon: getMapPin(),
          },
        });
      }

      // render the map
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col png-page-header">Locations</div>
          </div>
          <div className="row">
            <div className="col">
              <Content name="locationsSummary" showError={false} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FacilitiesMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&v=3.59`}
                loadingElement={<div />}
                containerElement={<div className="png-locations-map" />}
                mapElement={<div style={{ height: '100%' }} />}
                facilities={markers}
                onMapLoad={() => {}}
                onMapClick={() => {}}
                onMarkerRightClick={() => {}}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state) => ({
  // facilities
  facilities: state.context.facilities,

  // window width
  width: $(window).width(),
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, null)(OverviewMap));
